<template>
  <v-slide-x-reverse-transition>
    <div v-show="showHelpPanel" class="help-panel">
      <!-- Spinners in the background animation -->
      <ul class="help-background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <!-- The contents must be allowed independent scrolling to keep the background animations intact -->
      <div class="help-panel-contents pa-2">
        <v-card>
          <v-card-title>{{ getterGetHelp.title }}</v-card-title>
          <v-card-text>
            <v-fab-transition>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="help in getterGetHelp.items" :key="help.title" v-if="!help.only || (help.only ? help.only : '') === getterGetCurrentDeviceModelAlias">
                  <v-expansion-panel-header class="grey--text text--darken-1">{{ help.title }}</v-expansion-panel-header>
                  <v-expansion-panel-content v-for="(sentences, index) in help.text" :key="index">
                    <div>{{ sentences }}</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-fab-transition>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  props: {
    showHelpPanel: Boolean,
  },
  watch: {
    "$store.state.device.currentMenuPath": {
      handler(newVal){
        this.mutationSetHelp({ model: this.getterGetCurrentDeviceModel, path: newVal });
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters([ "getterGetHelp", "getterGetCurrentDeviceModel", "getterGetCurrentDeviceModelAlias"]),
  },
  methods: {
    ...mapMutations([ "mutationSetHelp" ]),
  }
};
</script>

<style scoped>
/* --------------Help Panel--------------- */
.help-panel {
  position: absolute;
  width: 100%;
  height: 100%;
}

.help-panel-contents {
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
}

@media (min-width: 1000px) {
  .help-panel {
    right: 0;
    width: 300px;
  }
  .help-panel-contents {
    height: 100%;
  }
}

.help-background {
  position: absolute;
  inset: 0 0 0 0;
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #4e54c8, #9e50c1, #c15065, #c1a550);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  overflow: hidden;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.help-background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: spin 19s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.help-background li:nth-child(0) {
  left: 87%;
  width: 176px;
  height: 176px;
  bottom: -176px;
  animation-delay: 1s;
}
.help-background li:nth-child(1) {
  left: 29%;
  width: 160px;
  height: 160px;
  bottom: -160px;
  animation-delay: 3s;
}
.help-background li:nth-child(2) {
  left: 36%;
  width: 136px;
  height: 136px;
  bottom: -136px;
  animation-delay: 4s;
}
.help-background li:nth-child(3) {
  left: 19%;
  width: 144px;
  height: 144px;
  bottom: -144px;
  animation-delay: 9s;
}
.help-background li:nth-child(4) {
  left: 62%;
  width: 123px;
  height: 123px;
  bottom: -123px;
  animation-delay: 17s;
}
.help-background li:nth-child(5) {
  left: 21%;
  width: 140px;
  height: 140px;
  bottom: -140px;
  animation-delay: 25s;
}
.help-background li:nth-child(6) {
  left: 67%;
  width: 127px;
  height: 127px;
  bottom: -127px;
  animation-delay: 26s;
}
.help-background li:nth-child(7) {
  left: 48%;
  width: 111px;
  height: 111px;
  bottom: -111px;
  animation-delay: 27s;
}
.help-background li:nth-child(8) {
  left: 67%;
  width: 112px;
  height: 112px;
  bottom: -112px;
  animation-delay: 17s;
}
.help-background li:nth-child(9) {
  left: 36%;
  width: 198px;
  height: 198px;
  bottom: -198px;
  animation-delay: 12s;
}
</style>
