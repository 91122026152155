<template>
  <div>
    <v-main class="ind-device-list-container">
      <IndividualDashboard :fetchedDevices="fetchedDevices"/>

      <div class="table-container">
        <v-toolbar class="elevation-0 tablePadding" dense flat>
          <v-toolbar-title class="mr-4 d-none d-md-inline">Routers</v-toolbar-title>
          <v-btn v-if="fetchedDevices.length === 0 || impersonateUser" class="button ml-sm-2" @click="open_add_device_dialog()" dark>
            <span class="d-none d-sm-inline">Add New Device</span>
            <span class="d-sm-none"><v-icon>mdi-plus</v-icon></span>
          </v-btn>
          <v-btn v-if="selectedRecords.length > 0 && !selectedRecords[0].hasOwnProperty('delete_status')" class="button ml-2" @click="open_delete_device_dialog" dark>
            <span class="d-none d-sm-inline ml-1">Delete</span>
            <span class="d-sm-none"><v-icon>mdi-delete</v-icon></span>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="container-wrapper">
            <v-text-field clearable append-icon="mdi-magnify blue--text" v-model="search" class="search-bar mr-1" label="Search" single-line style="max-width: 250px"></v-text-field>
          </div>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <v-data-table 
              dense 
              show-select 
              v-model="selectedRecords" 
              :headers="headers" 
              :search="search" 
              :items="fetchedDevices" 
              :items-per-page="10" 
              :loading="isTableLoading" 
              :no-data-text="isTableLoading ? 'Loading items...' : 'No devices available'" 
              item-key="router_id"
              :item-class="() => 'device-row'" 
              @click:row="open_device_details_dialog" 
              @toggle-select-all="toggleSelectAll"
              class="elevation-1 dtwidth caption device-table"
            >
              <template v-slot:[`item.config_updated_on`]="{ item }">
                <v-tooltip v-if="item.config_updated_on" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" dark small :color="getColor(item)">
                      <span class="caption">{{ mixinConvertUTCToDate(item.config_updated_on) }}</span>
                    </v-chip>
                  </template>
                  <span v-if="item.indicate_color === 'red'">The device has never connected to the server</span>
                  <span v-else-if="item.indicate_color === 'orange'">The device has yet to receive the latest configuration from the server</span>
                  <span v-else-if="item.indicate_color === 'green'">The configuration is up to date</span>
                </v-tooltip>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.last_reported_on`]="{ item }">
                <v-tooltip v-if="item.last_reported_on" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" dark small :color="mixinGetTimeDifference(item.last_reported_on) >= 1 ? 'grey' : '#4CAF50'">
                      <span class="caption">{{ mixinConvertUTCToDate(item.last_reported_on) }}</span>
                    </v-chip>
                  </template>
                  <span v-if="mixinGetTimeDifference(item.last_reported_on) >= 1">Last reported a day or more ago</span>
                  <span v-else>Last reported within 24hrs</span>
                </v-tooltip>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.last_reported_on`]="{ item }">
                <v-tooltip v-if="item.last_reported_on" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-on="on" v-bind="attrs" dark small :color="mixinGetTimeDifference(item.last_reported_on) >= 1 ? 'grey' : '#4CAF50'">
                      <span class="caption">{{ mixinConvertUTCToDate(item.last_reported_on) }}</span>
                    </v-chip>
                  </template>
                  <span v-if="mixinGetTimeDifference(item.last_reported_on) >= 1"
                    >Last reported a day or more ago</span
                  >
                  <span v-else>Last reported within 24hrs</span>
                </v-tooltip>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.mac_address`]="{ item }">
                <span>{{ item.mac_address != null || item.mac_address != undefined ? item.mac_address : "-" }}</span>
              </template>
              <template v-slot:[`item.license_status`]="{ item }">
                <span :style="{ color: item.license_status === 'trialing' || item.license_status.includes('rial') || item.license_status.toLowerCase() === 'active' ? 'green' : 'red' }">{{ capitalizeFirstLetter(item.license_status) }}</span>
              </template>
              <template v-slot:[`item.expire_date`]="{ item }">
                <span
                  class="mr-2"
                  v-html="formatDateWithTime(item.expire_date)"
                ></span>
              </template>
              <template v-slot:[`item.signal`]="{ item }">
                <SignalBar v-if="shouldShowSignal(item.router_model)" :signal="item.signal" />
                <span v-else class="ml-1"> - </span>
              </template>
              <template v-slot:[`item.technology_type`]="{ item }">
                <span class="ml-1" >
                  {{ convert_connectedStatus(item.technology_type) }}
                </span>
              </template>
              <template v-slot:[`item.usage`]="{ item }">
                <span>{{ item.usage != null || item.usage != undefined ? item.usage : "0.0" }}</span>
              </template>
              <template v-slot:[`item.router_id`]="{ item }">
                <span>{{ item.router_id.split("/")[1] }}</span>
              </template>
              <template v-slot:[`item.online_status`]="{ item }">
                <span v-if="item.hasOwnProperty('delete_status')" class="ml-2 orange--text text-darken-1">Awaiting Factory Reset</span>
                <span v-else class="ml-2" :class="item.online_status == 'ONLINE' ? 'caption green--text text--darken-1' : 'caption red--text'">
                  {{ item.online_status == "ONLINE" ? "Online" : "Offline" }}
                </span>
              </template>
              <template v-slot:header.online_status="{ header }">
                <span class="ml-2">
                  {{ header.text }}
                </span>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="item.hasOwnProperty('delete_status')" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="open_device_details_dialog(item)">
                      <v-list-item-title>Device Info</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="getCurrentUserRole === 'SU_ADMIN'" @click="edit_device(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="open_configs_dialog(item)">
                      <v-list-item-title>Configuration</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="getCurrentUserRole != 'P_ADMIN'" @click="open_remote_command_dialog(item)">
                      <v-list-item-title>Remote Command</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:footer>
                <div class="footer-height">
                  <LoadingIcon v-if="isTableLoading" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>
                  <span v-if="isTableLoading" style="position: absolute" class="ml-10 mt-5">Loading routers...</span>
                  <span v-else>
                    <v-icon @click="fetch_user_devices()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                    <span style="position: absolute" class="d-none d-sm-inline ml-11 pl-0 mt-5"> Last updated: {{ mixinGetLastUpdatedTime }} </span>
                  </span>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </v-main>

    <!-- Modal Dialog components -->
    <TutorialSlides v-if="firstDataCheck" :demoDialog="demoDialog" @clicked="close_dialog" :modelType="modelType" />
    <IndividualAddDevicesDialog :addRouterDialog="addRouterDialog" @close="close_add_device_dialog" />
    <DeleteDevicesDialog :deleteRouterDialog="deleteRouterDialog" :selectedRecords="selectedRecords" @close="close_delete_device_dialog" />

    <div v-if="singleRouterInfo"> <!-- Don't try to render these components until a router is selected -->
      <DeviceDetailsDialog :deviceDetailsDialog="deviceDetailsDialog" :singleRouterInfo="singleRouterInfo" @openConfigs="open_configs_dialog" @close="close_device_details_dialog" />
      <DeviceConfigDialog :deviceConfigDialog="deviceConfigDialog" :isGroupConfig="false" :singleRouterInfo="singleRouterInfo" @close="close_configs_dialog"/>
      <RemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :singleRouterInfo="singleRouterInfo" @close="close_remote_command_dialog"> </RemoteCommandDialog>
    </div>
    
  </div>
</template>

<script>
import IndividualDashboard from "@/components/Dashboard/IndividualDashboard.vue";
import DeviceDetailsDialog from "@/components/Devices/DeviceDialogs/DeviceDetailsDialog/DeviceDetailsDialog.vue";
import DeviceConfigDialog from "@/components/DeviceConfigurations/DeviceConfigDialog.vue";
import DeleteDevicesDialog from "@/components/Devices/DeviceDialogs/DeleteDevicesDialog.vue";
import RemoteCommandDialog from "@/components/Devices/DeviceDialogs/RemoteCommandDialog.vue";
import { API, graphqlOperation } from "aws-amplify";
import { get_router_details_for_the_particular_user } from "@/graphql/queries.js";
import TutorialSlides from "@/components/Configuration/TutorialSlides.vue";
import IndividualAddDevicesDialog from "@/components/Devices/DeviceDialogs/IndividualAddDevicesDialog.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import SignalBar from "./SharedComponents/SignalBar.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils";

import { requestQueryToRouter } from "@/services";

import { mapMutations, mapActions, mapGetters } from "vuex";


export default {
  mixins: [mixin_time_utils, router_model_utils],
  components: {
    TutorialSlides,
    DeviceDetailsDialog,
    IndividualAddDevicesDialog,
    DeleteDevicesDialog,
    LoadingIcon,
    DeviceConfigDialog,
    RemoteCommandDialog,
    IndividualDashboard,
    SignalBar,
  },
  data() {
    return {
      deviceConfigDialog: false,
      Router_Obj: {},
      search: "",
      dropDownItems: [
        { value: "router_model", text: "Model" },
        { value: "registered", text: "Registered Status" },
        { value: "online_status", text: "Online Status" },
      ],
      isLoading: false,
      headers: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "online_status" },
        { text: "Device Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        { text: "Model", align: "start", sortable: true, class: "headerColor white--text ", value: "router_model" },
        { text: "Subscription", align: "start", sortable: true, class: "headerColor white--text ", value: "license_status" },
        { text: "Expiration Date", align: "start", sortable: true, class: "headerColor white--text", value: "expire_date" },
        { text: "Device SN", sortable: true, class: "headerColor white--text ", value: "router_id" },
        { text: "IMEI", sortable: true, class: "headerColor white--text ", value: "imei_number" },
        { text: "Carrier", sortable: true, class: "headerColor white--text ", value: "carrier_info" },
        { text: "Config Updated", sortable: true, class: "headerColor white--text ", value: "config_updated_on" },
        { text: "Last Reported", sortable: true, class: "headerColor white--text ", value: "last_reported_on" },
        { text: "Device Version", sortable: true, class: "headerColor white--text ", value: "firmware_version" },
        { text: "Description", sortable: true, class: "headerColor white--text ", value: "router_description" },
        { text: "Partner", sortable: true, class: "headerColor white--text ", value: "partner_name" },
        { text: "Usage(GB)", sortable: true, class: "headerColor white--text ", value: "data_usage" },
        { text: "Technology", sortable: true, class: "headerColor white--text ", value: "technology_type" },
        { text: "Signal", sortable: true, class: "headerColor white--text ", value: "signal" },
        { text: "Actions", class: "headerColor white--text ", value: "Actions" },
      ],
      fetchedDevices: [],
      selectedRecords: [],

      isTableLoading: false,
      addRouterDialog: false,
      routerID: [],
      deleteRouterDialog: false,
      routerObj: {},

      firstDataCheck: null,
      demoDialog: true,
      modelType: "",

      singleRouterInfo: null,
      deviceDetailsDialog: false,

      //RemoteCommand Component Variables
      remoteCommandDialog: false,

      // used for constantly requesting device list to the server every minute
      queryInterval: null,
    };
  },
  watch: {
    fetchedDevices: {
      async handler(newVal) {
        if (newVal.some((device) => device.hasOwnProperty("delete_status"))) {
          if (this.queryInterval) {
            clearInterval(this.queryInterval);
          }
          this.queryInterval = setInterval(() => {
            this.fetch_user_devices();
          }, 60000);
        } else {
          if (this.queryInterval) {
            clearInterval(this.queryInterval);
          }
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.queryInterval);
  },
  async created() {
    if (JSON.parse(localStorage.getItem("firstLogin"))) {
      this.firstDataCheck = true;
      this.modelType = localStorage.getItem("modelInfo");
    }
    var navigationType = window.performance.getEntriesByType("navigation")[0].type;
    if (navigationType == "reload") {
      localStorage.setItem("firstLogin", false);
      localStorage.setItem("modelInfo", "");
    }
    await this.fetch_user_devices();
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetDeviceConfigurations", "getterGetIsImpersonating"]),
    getDeviceConfigurations() {
      return this.getterGetDeviceConfigurations;
    },
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    impersonateUser() {
      return this.getterGetIsImpersonating;
    },
  },
  methods: {
    ...mapActions(["actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // Device Config V2
    async open_configs_dialog(item) {
      this.singleRouterInfo = item;
      this.mutationOpenProgressBar();
      try {
        await this.actionGetSingleDeviceInfo(item.router_id);
        await this.actionGetDeviceConfiguration(item.router_id);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.deviceConfigDialog = true;
        this.mutationCloseProgressBar();
      }
    },
    close_configs_dialog() {
      this.deviceConfigDialog = false;
    },
    //method to add individual routers
    open_add_device_dialog() {
      if (this.fetchedDevices.length > 4) return this.triggerSnackBar("error", "Max Number Of Devices Can Be Up to 5");
      this.addRouterDialog = true;
    },
    close_add_device_dialog(val) {
      if (val === 0) {
        this.addRouterDialog = false;
      } else {
        this.addRouterDialog = false;
        this.fetch_user_devices();
      }
    },

    // this is never used
    close_dialog(val) {
      this.demoDialog = false;
    },

    //method to indicated the color codes
    getColor(item) {
      const colorTable = {
        red: "#F44336",
        green: "#4CAF50",
        orange: "#FF9800",
      };
      return colorTable[item.indicate_color];
    },

    // method to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },

    // ------------ Device Delete related --------------------
    open_delete_device_dialog() {
      if (this.selectedRecords.some((record) => record.hasOwnProperty("delete_status"))) {
        this.triggerSnackBar("error", "This device has already been deleted and waiting for device factory reset");
        return;
      }
      this.deleteRouterDialog = true;
    },
    // after deleting device(s), the frontend client will eventually refresh the list after 3 seconds
    // this is to compensate for the time it takes for the appsync server to update its database
    // the status of the device should be changed "Awaiting Factory Reset"
    close_delete_device_dialog(deleteObj) {
      if (deleteObj.status === 0) {
        this.deleteRouterDialog = false;
      } else if (deleteObj.status === 1) {
        this.selectedRecords = [];
        this.deleteRouterDialog = false;
        setTimeout(() => {
          this.fetch_user_devices();
        }, 3000);
      }
    },

    // --------------- Row Dialog Related ----------------
    async open_device_details_dialog(item) {
      if (item.hasOwnProperty("delete_status")) {
        this.triggerSnackBar("error", "This device has already been deleted and waiting for device factory reset");
        return;
      }
      this.mutationOpenProgressBar();
      try {
        this.singleRouterInfo = item;
        await requestQueryToRouter(item.router_id);
        await this.actionGetSingleDeviceInfo(item.router_id);
        await this.actionGetDeviceConfiguration(item.router_id);

        this.deviceDetailsDialog = true;
      } catch (err) {
        // Do not show any errors
      } finally {
        this.mutationCloseProgressBar();
      }
    },
    close_device_details_dialog() {
      this.deviceDetailsDialog = false;
    },

    async fetch_user_devices() {
      this.isTableLoading = true;
      var data = {};
      if (this.getCurrentUserRole == "G_M_ADMIN") {
        data = {
          user_id: this.getCurrentUserID,
          router_group_id: this.getterGetUserInfo.user.router_group_id,
        };
      } else {
        data = {
          user_id: this.getCurrentUserID,
        };
      }
      try {
        let result = await API.graphql(
          graphqlOperation(get_router_details_for_the_particular_user, {
            input: data,
          })
        );
        var response = JSON.parse(result.data.get_router_details_for_the_particular_user);
        this.fetchedDevices = response;
        this.mixinResetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        this.fetchedDevices = [];
      } finally {
        this.isTableLoading = false;
      }
    },

    // ----------- Remote Command --------------
    open_remote_command_dialog(item) {
      this.singleRouterInfo = item;
      this.remoteCommandDialog = true;
    },
    close_remote_command_dialog() {
      this.remoteCommandDialog = false;
    },

    //Method to call configuration menu of a device on action menu
    // async configuration_menu(item) {
    //   await this.open_device_details_dialog(item);
    //   this.$refs.routerDialog.open_device_configuration_dialog();
    // },

    capitalizeFirstLetter(str) {
      //Remove once all devices convert from trialing to "Free Trial"
      if (str === "trialing") {
        return "Free Trial";
      }
      return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    formatDateWithTime(dateString) {
      // Create a new Date object from the input date string
      const currentDate = new Date();
      const endDate = new Date(dateString);
      //Calculate days remaining
      const msDifference = endDate - currentDate;
      const millisecondsInADay = 86400000;
      const daysRemaining = Math.floor(msDifference / millisecondsInADay);
      // Extract the month, day, and year from the Date object
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
      const day = String(endDate.getDate()).padStart(2, "0");
      const year = endDate.getFullYear();
      // Extract the hours, minutes, and seconds from the Date object
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      // Return the formatted date string in MM/DD/YYYY HH:MM:SS format
      return `${month}/${day}/${year}`;
    },
    toggleSelectAll(item) {
      if(item.value) {
        this.selectedRecords = this.fetchedDevices;
      } else {
        this.selectedRecords = [];
      }
    }
  },
};
</script>

<style scoped>
.device-table >>> .device-row {
  cursor: pointer;
}
</style>

