import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,[_vm._v("Valid")]),_c(VTabItem,[_c(VDataTable,{staticClass:"elevation-1 dtwidth",attrs:{"dense":"","headers":_vm.validHeaders,"items":_vm.validData,"items-per-page":20}}),_c(VBtn,{staticClass:"ma-4 white--text",attrs:{"rounded":"","color":"primary","loading":_vm.isSubmitting,"disabled":_vm.invalidData.length > 0 || _vm.validData.length === 0},on:{"click":function($event){return _vm.submit_mac_address_form()}}},[_vm._v("Submit")])],1),_c(VTab,[_vm._v("Invalid")]),_c(VTabItem,[_c(VDataTable,{staticClass:"elevation-0 dtwidth",attrs:{"dense":"","headers":_vm.validHeaders,"items":_vm.invalidData,"hide-default-footer":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }