<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4 mt-2">Port Forwarding</h3>

    <div>
      <v-switch ref="Enable" v-model="Enable" class="mt-n1 d-inline-block" @click="mixinUpdateFormField('security.portBlackList.Enable', `${configPathPortForwarding}.Enable`, Enable ? 1 : 0)">
        <template v-slot:label>
          <div>Enable</div>
        </template>
      </v-switch>
    </div>

    <v-expand-transition>
      <div v-show="Enable">
        <v-row class="mb-4" no-gutters>
          <v-col cols="10">
            <v-text-field ref="PortMappingList" class="list-proxy-text-field" v-model="PortMappingList" :rules="portForwardingListValidationRule()" hidden></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center" cols="2">
            <v-btn small @click="open_port_mapping_list_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
          </v-col>
        </v-row>
    
        <v-data-table show-select single-select dense :items="PortMappingList" :headers="portMappingListHeaders">
    
          <template v-slot:item.data-table-select="{ item, index }">
            <v-switch :input-value="item.ActiveRule" @click.stop="change_port_forwarding_enable_state(item, index)" hide-details style="margin: auto"></v-switch>
          </template>

          <template v-slot:item.Protocol="{ item }">
            <span>{{ mixinInternetProtocolReturn(item.Protocol) }}</span>
          </template>
    
          <template v-slot:[`item.Actions`]="{ item, index }">
            <v-menu bottom left content-class="menu-offset">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
    
              <v-list dense>
                <v-list-item @click="open_port_mapping_list_dialog('edit', item, index)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteFromList(item, index)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-expand-transition>
    <!-- other form fields -->
    <PortMappingListDialog :portMappingListDialog="portMappingListDialog" :portMappingListDialogMode="portMappingListDialogMode" :PortMappingList="PortMappingList" @close="close_port_mapping_list_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import PortMappingListDialog from "../SubDialogs/PortMappingListDialog.vue";
import DeleteDialog from "../../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    PortMappingListDialog,
    DeleteDialog
  },
  data() {
    return {
      portMappingListHeaders: [
        { text: "Rule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "RuleName" },
        { text: "WAN Port Range", sortable: true, class: "headerColor white--text ", value: "WANPortStart" },
        { text: "Local IP", sortable: true, class: "headerColor white--text ", value: "LANIPAddress" },
        { text: "Local Port", sortable: true, class: "headerColor white--text ", value: "LANPort" },
        { text: "Protocol", sortable: true, class: "headerColor white--text ", value: "Protocol" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      // PortForwarding
      Enable: false,
      PortMappingList: [], // your table data

      configPathPortForwarding: "configuration_module.PortForwarding.Value",

      portMappingListDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      portMappingListDialogMode: {},

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initPortForwarding();
  },
  watch: {
    // The apnList is artificially bound to v-text-field for a proper form validation
    // Since
    PortMappingList: {
      handler(newVal) {
        this.$nextTick(() => {
          this.mixinUpdateFormField("security.portBlackList.PortMappingList", `${this.configPathPortForwarding}.PortMappingList`, this.PortMappingList);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initPortForwarding() {
      const defaultPortForwardingInfo = this.getterGetNewDeviceConfigurations.configuration_module.PortForwarding.Value;

      this.Enable = defaultPortForwardingInfo.hasOwnProperty("Enable") ? (defaultPortForwardingInfo.Enable === 1 ? true : false) : false;
      this.PortMappingList = defaultPortForwardingInfo.hasOwnProperty("PortMappingList") ? [...defaultPortForwardingInfo.PortMappingList] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("portBlackList", "security");
      });
    },
    portForwardingListValidationRule() {
      return [
        value => this.PortMappingList.length <= 20 || "There cannot be more than 20 items in the Port Mapping List"
      ];
    },

    change_port_forwarding_enable_state(item, itemIndex){
      this.PortMappingList.forEach((listItem, listIndex) => {
        if(itemIndex === listIndex){
          listItem.ActiveRule = item.ActiveRule === 0 ? 1 : 0;
        }
      })
    },
    deleteFromList(item, index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.PortMappingList.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.PortMappingList.forEach((listItem, index) => {
          listItem.Index = index;
        })
      }
    },
    // a method for both edit and add
    open_port_mapping_list_dialog(mode, item, index) {
      if (this.PortMappingList.length >= 20 && mode === "add") {
        this.triggerSnackBar("error", "You cannot have more than 20 items in the Port Mapping List");
        return;
      }
      this.portMappingListDialog = true;
      this.portMappingListDialogMode = {
        mode,
      };
      if (mode === "edit") {
        this.portMappingListDialogMode.item = item;
        this.portMappingListDialogMode.index = index;
      }
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_port_mapping_list_dialog(dialogObj) {
      if (dialogObj.status === 1) {
        if (dialogObj.mode === "edit") {
          this.$set(this.PortMappingList, dialogObj.index, dialogObj.item);
        } else if (dialogObj.mode === "add") {
          this.PortMappingList.push(dialogObj.item);
        }
      }
      this.portMappingListDialog = false;
    },
  },
};
</script>

<style scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
