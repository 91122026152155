<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4 mt-6">Port Blacklist</h3>

    <div>
      <v-switch ref="Enable" v-model="Enable" class="mt-n1 d-inline-block" @click="mixinUpdateFormField('security.portBlackList.Enable', `${configPathPortWhilteList}.Enable`, Enable ? 1 : 0)">
        <template v-slot:label>
          <div>Enable</div>
        </template>
      </v-switch>
    </div>

    <v-expand-transition>
      <div v-show="Enable">
        <v-row class="mb-4" no-gutters>
          <v-col cols="10">
            <v-text-field ref="PortBlackList" class="list-proxy-text-field" v-model="PortBlackList" :rules="portBlackListValidationRule()" hidden></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center" cols="2">
            <v-btn small @click="open_port_blacklist_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
          </v-col>
        </v-row>
    
        <v-data-table show-select single-select dense :items="PortBlackList" :headers="portBlackListHeaders">
    
          <template v-slot:item.data-table-select="{ item, index }">
            <v-switch :input-value="item.ActiveRule" @click.stop="change_port_blacklist_enable_state(item, index)" hide-details style="margin: auto"></v-switch>
          </template>

          <template v-slot:item.Protocol="{ item }">
            <span>{{ mixinInternetProtocolReturn(item.Protocol) }}</span>
          </template>
    
          <template v-slot:[`item.Actions`]="{ item, index }">
            <v-menu bottom left content-class="menu-offset">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
    
              <v-list dense>
                <v-list-item @click="open_port_blacklist_dialog('edit', item, index)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteFromList(item, index)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-expand-transition>
    <!-- other form fields -->
    <PortBlackListDialog :portBlackListDialog="portBlackListDialog" :portBlackListDialogMode="portBlackListDialogMode" :PortBlackList="PortBlackList" @close="close_port_blacklist_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import PortBlackListDialog from "../SubDialogs/PortBlackListDialog.vue";
import DeleteDialog from "../../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    PortBlackListDialog,
    DeleteDialog
  },
  data() {
    return {
      portBlackListHeaders: [
        { text: "Rule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "RuleName" },
        { text: "WAN IP Address", sortable: true, class: "headerColor white--text ", value: "WANIPAddress" },
        { text: "WAN Port", sortable: true, class: "headerColor white--text ", value: "WANPort" },
        { text: "Protocol", sortable: true, class: "headerColor white--text ", value: "Protocol" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      // PortForwarding
      Enable: false,
      PortBlackList: [], // your table data

      configPathPortWhilteList: "configuration_module.PortBlackList.Value",

      portBlackListDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      portBlackListDialogMode: {},

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initPortBlackList();
  },
  watch: {
    // The apnList is artificially bound to v-text-field for a proper form validation
    PortBlackList: {
      handler(newVal) {
        this.$nextTick(() => {
          this.mixinUpdateFormField("security.portForwarding.PortBlackList", `${this.configPathPortWhilteList}.PortBlackList`, this.PortBlackList);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initPortBlackList() {
      const defaultPortBlackListInfo = this.getterGetNewDeviceConfigurations.configuration_module.PortBlackList.Value;

      this.Enable = defaultPortBlackListInfo.hasOwnProperty("Enable") ? (defaultPortBlackListInfo.Enable === 1 ? true : false) : false;
      this.PortBlackList = defaultPortBlackListInfo.hasOwnProperty("PortBlackList") ? [...defaultPortBlackListInfo.PortBlackList] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("portBlackList", "security");
      });
    },
    portBlackListValidationRule() {
      return [
        value => this.PortBlackList.length <= 20 || "There cannot be more than 20 items in the Port Blacklist"
      ];
    },

    change_port_blacklist_enable_state(item, itemIndex){
      this.PortBlackList.forEach((listItem, listIndex) => {
        if(itemIndex === listIndex){
          listItem.ActiveRule = item.ActiveRule === 0 ? 1 : 0;
        }
      })
    },
    deleteFromList(item, index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.PortBlackList.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.PortBlackList.forEach((listItem, index) => {
          listItem.Index = index;
        })
      }
    },
    // a method for both edit and add
    open_port_blacklist_dialog(mode, item, index) {
      if (this.PortBlackList.length >= 20 && mode === "add") {
        this.triggerSnackBar("error", "You cannot have more than 20 items in the Port Mapping List");
        return;
      }
      this.portBlackListDialog = true;
      this.portBlackListDialogMode = {
        mode,
      };
      if (mode === "edit") {
        this.portBlackListDialogMode.item = item;
        this.portBlackListDialogMode.index = index;
      }
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_port_blacklist_dialog(dialogObj) {
      if (dialogObj.status === 1) {
        if (dialogObj.mode === "edit") {
          this.$set(this.PortBlackList, dialogObj.index, dialogObj.item);
        } else if (dialogObj.mode === "add") {
          this.PortBlackList.push(dialogObj.item);
        }
      }
      this.portBlackListDialog = false;
    },
  },
};
</script>

<style scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
