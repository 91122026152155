<template>
  <v-main>
    <!-- tool bar to diaply title and add action -->
    <v-toolbar class="elevation-0 tablePadding" dense flat>
      <v-toolbar-title class="mt-1">Register Default MAC Address</v-toolbar-title>
      <v-spacer></v-spacer>
      <OpenExcelFile @uploaded="validateExcelFileUpload" />
    </v-toolbar>

    <SubmitMACAddressForm :validData="validData" :invalidData="invalidData"/>
  </v-main>
</template>
<script>
import OpenExcelFile from "@/components/RegisterMACAddress/OpenExcelFile.vue";
import SubmitMACAddressForm from "@/components/RegisterMACAddress/SubmitMACAddressForm.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";

export default {
  mixins: [mixin_form_validation_utils],
  components: {
    OpenExcelFile,
    SubmitMACAddressForm,
  },
  data() {
    return {
      validData: [],
      invalidData: [],
    };
  },
  created() {},
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    validateExcelFileUpload(excelDeviceList) {
      this.invalidData = [];
      this.validData = [];

      if (excelDeviceList.length == 0) return this.triggerSnackBar("error", "The data cannot be empty.");
      
      excelDeviceList.forEach((uploadedData) => {
        // make a json copy to derefence it from the original structure
        let errorRow = { ...uploadedData };
        let isRowValid = true;

        if (uploadedData.IMEI == undefined || uploadedData.MACAddress == undefined) {
          isRowValid = false;
        }

        const IMEICheck = this.mixinScriptValidation(uploadedData.IMEI, this. mixinIMEIRule());
        if (!IMEICheck.valid) {
          errorRow.IMEI = `(${uploadedData.IMEI}) ${IMEICheck.message}`
          isRowValid = false;
        }

        const MACAddressCheck = this.mixinScriptValidation(uploadedData.MACAddress, this. mixinRawMACAddressRule());
        if (!MACAddressCheck.valid) {
          errorRow.MACAddress = `(${uploadedData.MACAddress}) ${MACAddressCheck.message}`
          isRowValid = false;
        }

        // push item in the list
        isRowValid ? this.validData.push(uploadedData) : this.invalidData.push(errorRow);
      });

      if(this.invalidData.length > 0) {
        this.triggerSnackBar("error", "The excel file you've uploaded has some errors. Click on invalid tab for more information.");
      }
    },
  },
};
</script>
