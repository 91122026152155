<template>
  <v-dialog overflow-hidden v-model="portBlackListDialog" persistent max-width="600px" scrollable>
    <DialogCard :modalIcon="'serial-port'" :modalTitle="'Port Blacklist'">
      <template #header>
        <v-btn icon x-small @click="close_port_blacklist_dialog()">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">

          <div>
            <v-switch ref="ActiveRule" class="form-text-box d-inline-block mb-6" dense v-model="ActiveRule" hide-details>
              <template v-slot:label>
                <transition-group name="fade" tag="div" class="radio-button-enable-disable-container">
                  <div v-if="ActiveRule" key="Enabled">Enabled</div>
                  <div v-else key="Disabled">Disabled</div>
                </transition-group>
              </template>
            </v-switch>
          </div>

          <v-text-field ref="RuleName" class="form-text-box" outlined dense label="Profile Name" placeholder="Profile Name" v-model="RuleName" :rules="mixinCommonNameRule()" autocomplete="new-password"></v-text-field>

          <v-text-field ref="WANIPAddress" class="form-text-box" outlined dense label="WAN IP Address" placeholder="WAN IP Address" v-model="WANIPAddress" :rules="mixinMandatoryIPv4Rule()" autocomplete="new-password"></v-text-field>

          <v-text-field ref="WANPort" class="form-text-box" outlined dense label="WAN Port" placeholder="WAN Port" v-model.number="WANPort" :rules="mixinPortNumberRule()" autocomplete="new-password" @keypress="mixinNumberOnlyKeyPress($event)" maxlength="5" hide-spin-buttons></v-text-field>

          <v-select ref="Protocol" class="form-text-box" outlined dense label="Internet Protocol" :items="mixinGetInternetProtocolList()" v-model="Protocol"></v-select>
        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_port_blacklist_dialog()">Cancel</v-btn>
        <v-btn small @click="save_port_blacklist()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    DialogCard,
  },
  props: {
    portBlackListDialog: Boolean,
    portBlackListDialogMode: Object,
    PortBlackList: Array,
  },

  data() {
    return {
      ActiveRule: false,
      RuleName: "",
      WANPort: "",
      WANIPAddress: "",
      Protocol: -1,
    };
  },
  watch: {
    portBlackListDialog: {
      handler(newVal) {
        if (newVal) {
          this.initPortBlackListDialog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initPortBlackListDialog() {
      const mode = this.portBlackListDialogMode.mode;
      const item = this.portBlackListDialogMode.item;

      if (mode === "add") {
        this.RuleName = "";
        this.WANIPAddress = "";
        this.WANPort = "";
        this.Protocol = 0;
      } else if (mode === "edit") {
        this.ActiveRule = this.mixinIsDefinedAndNotNull(item.ActiveRule) ? (item.ActiveRule ? true : false) : false;
        this.RuleName = this.mixinIsDefinedAndNotNull(item.RuleName) ? item.RuleName : "";
        this.WANIPAddress = this.mixinIsDefinedAndNotNull(item.WANIPAddress) ? item.WANIPAddress : "";
        this.WANPort = this.mixinIsDefinedAndNotNull(item.WANPort) ? item.WANPort : "";
        this.Protocol = this.mixinIsDefinedAndNotNull(item.Protocol) ? item.Protocol : 0;
      }
    },
    save_port_blacklist() {
      if (!(this.$refs.form && this.$refs.form.validate())) {
        this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
        return;
      }
      const mode = this.portBlackListDialogMode.mode;
      const item = {
        Index: mode === "add" ? this.PortBlackList.length : this.portBlackListDialogMode.item.Index,
        ActiveRule: this.ActiveRule ? 1 : 0,
        RuleName: this.RuleName,
        WANIPAddress: this.WANIPAddress,
        WANPort: this.WANPort,
        Protocol: this.Protocol,
      };

      const dialogObj = {
        status: 1,
        mode: mode,
        item: item,
      };
      if (mode === "edit") dialogObj.index = this.portBlackListDialogMode.index;

      // Set item to send back to ApnInfo.vue component for edit or add
      this.$emit("close", dialogObj);
    },
    //method invokes on close actions
    close_port_blacklist_dialog() {
      this.$emit("close", { status: 0 });
    },
  },
};
</script>

<style scoped>
.radio-button-enable-disable-container {
  position: relative;
}

.radio-button-enable-disable-container > * {
  position: absolute;
  top: -0.67rem;
  left: 0.5rem;
}
</style>