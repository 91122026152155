<template>
  <div>
    <v-main>
      <!-- tool bar to diaply title and add action -->
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title class="mt-1">Bulk Registers</v-toolbar-title>
        <v-spacer></v-spacer>
        <ImportExcel :validateExcelFileUpload="validateExcelFileUpload" />
      </v-toolbar>


      <ExcelValidation :validData="validData" :invalidData="invalidData" :subscriptionData="subscriptionData"/>

    </v-main>
  </div>
</template>
<script>
import ImportExcel from "@/components/BulkUpload/ImportExcel.vue";
import ExcelValidation from "@/components/BulkUpload/ExcelValidation.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
// import { does_subscription_exist } from "@/graphql/queries/stripeQueries.js";

export default {
  mixins: [mixin_form_validation_utils],
  components: {
    ImportExcel,
    ExcelValidation,
  },
  data() {
    return {
      validData: [],
      invalidData: [],
      subscriptionData: {},
      organizationList: [],
      countryList: [],
      modelList: [],
    };
  },
  created() {},
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    //method to validate the empty record
    // async validate_add_data(results, header) {

    //   try {
    //     this.validation_data(results, header);
        //Check if user/organization already contains subscription or not
        // const doesSubscriptionExist = await API.graphql(
        //   graphqlOperation(does_subscription_exist, {
        //     input: {
        //       organization_list: this.organizationList[0],
        //       model: this.modelList[0],
        //       license_country: this.countryList[0],
        //       acc_type: "business",
        //     },
        //   })
        // );
        // this.subscriptionData = JSON.parse(
        //   doesSubscriptionExist.data.does_subscription_exist
        // );
      // } catch (error) {
      //   this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
        // if (error.errors[0].message.includes("does not exist in stripe")) {}
      // } finally {
      //   this.excelUploadStatus = true;
      // }

    // },

    validateExcelFileUpload(excelDeviceList, header) {
      this.invalidData = [];
      this.validData = [];

      if (excelDeviceList.length == 0) return this.triggerSnackBar("error", "The data cannot be empty.");
      
      this.modelList = [];
      this.countryList = [];
      this.organizationList = [];

      excelDeviceList.forEach((uploadedData) => {
        if (uploadedData.Model == undefined || uploadedData.FID == undefined || uploadedData.ProductName == undefined || uploadedData.LicenseType == undefined || uploadedData.LicenseName == undefined) {
          return this.invalidData.push(uploadedData);
        }

        // Main Wi-Fi validation
        // Note that neither Main Wi-Fi nor Guest Wi-Fi are mandatory. There can be either one or the other or none
        const hasMainWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasMainWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasMainWiFiSSID || hasMainWiFiPassword) {
          if (hasMainWiFiSSID != hasMainWiFiPassword) {
            this.triggerSnackBar("error", `Main Wi-Fi: You must have both SSID and Password or have neither.`);
            return this.invalidData.push(uploadedData);
          }
          const mainWifiPasswordCheck = this.mixinScriptValidation(uploadedData.MainWiFiPassword, this.mixinWifiPasswordRule());
          const mainWifiSSIDCheck = this.mixinScriptValidation(uploadedData.MainWiFiSSID, this.mixinWifiNameRule());
          if (!mainWifiPasswordCheck.valid) {
            this.triggerSnackBar("error", `Main Wi-Fi Password: ${mainWifiPasswordCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (!mainWifiSSIDCheck.valid) {
            this.triggerSnackBar("error", `Main Wi-Fi SSID: ${mainWifiSSIDCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
        }

        // Guest Wi-Fi Validation
        const hasGuestWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasGuestWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasGuestWiFiSSID || hasGuestWiFiPassword) {
          if (hasGuestWiFiSSID != hasGuestWiFiPassword) {
            this.triggerSnackBar("error", `Guest Wi-Fi: You must have both SSID and Password or have neither.`);
            return this.invalidData.push(uploadedData);
          }
          const guestWifiPasswordCheck = this.mixinScriptValidation(uploadedData.GuestWiFiPassword, this.mixinWifiPasswordRule());
          const guestWifiSSIDCheck = this.mixinScriptValidation(uploadedData.GuestWiFiSSID, this.mixinWifiNameRule());
          if (!guestWifiPasswordCheck.valid && uploadedData.hasOwnProperty("GuestWiFiPassword")) {
            this.triggerSnackBar("error", `Guest Wi-Fi Password: ${guestWifiPasswordCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (!guestWifiSSIDCheck.valid && uploadedData.hasOwnProperty("GuestWiFiSSID")) {
            this.triggerSnackBar("error", `Guest Wi-Fi SSID: ${guestWifiSSIDCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
        }

        const hasMainWiFiRadioBand = uploadedData.hasOwnProperty("MainWiFiRadioBand");
        const hasGuestWiFiRadioBand = uploadedData.hasOwnProperty("GuestWiFiRadioBand");
        const radioBandRule = [
          (value) => /^[0-2]$/.test(value) || "Radio band must be numbers between 0~2."
        ];
        if (hasMainWiFiRadioBand || hasGuestWiFiRadioBand) {
          if (hasMainWiFiRadioBand != hasGuestWiFiRadioBand) {
            this.triggerSnackBar("error", `Wi-Fi Radio Band: You must have Radio Bands for both Main and Guest Wi-Fi or enter neither.`);
            return this.invalidData.push(uploadedData);
          }
          const mainWiFiRadioBandCheck = this.mixinScriptValidation(uploadedData.MainWiFiRadioBand, radioBandRule);
          const guestWiFiRadioBandCheck = this.mixinScriptValidation(uploadedData.GuestWiFiRadioBand, radioBandRule);
          if (!mainWiFiRadioBandCheck.valid && uploadedData.hasOwnProperty("MainWiFiRadioBand")) {
            this.triggerSnackBar("error", `Main Wi-Fi Radio Band: ${mainWiFiRadioBandCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (!guestWiFiRadioBandCheck.valid && uploadedData.hasOwnProperty("GuestWiFiRadioBand")) {
            this.triggerSnackBar("error", `Guest Wi-Fi Radio Band: ${guestWiFiRadioBandCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (uploadedData.MainWiFiRadioBand == uploadedData.GuestWiFiRadioBand) {
            this.triggerSnackBar("error", "The Main Wi-Fi Band and Guest Wi-Fi Band cannot be the same.");
            return this.invalidData.push(uploadedData);
          }
        }


        // Admin password
        const adminPasswordCheck = this.mixinScriptValidation(uploadedData.RouterAdminPassword, this.mixinRouterAdminPasswordRule());
        if (!adminPasswordCheck.valid) {
          this.triggerSnackBar("error", `Router Admin Password: ${adminPasswordCheck.message}`);
          return this.invalidData.push(uploadedData);
        }

        this.validData.push(uploadedData);
        // This is only for verifying whethere there are two or more oraganizations, models, or countries in each respective lists
        if (uploadedData.Organization != undefined && !this.organizationList.includes(uploadedData.Organization)) {
          this.organizationList.push(uploadedData.Organization);
        }
        if (!this.modelList.includes(uploadedData.Model)) {
          this.modelList.push(uploadedData.Model);
        }
        // console.log("Country: ", uploadedData.LicenseCountry);
        if (!this.countryList.includes(uploadedData.LicenseCountry)) {
          this.countryList.push(uploadedData.LicenseCountry);
        }
      });

      const throwError = (text, list) => {
        throw new Error(`There is more than 1 ${text} in your excel sheet: [${list.join(", ")}]`);
      };

      if (this.modelList.length > 1) throwError("model", this.modelList);
      if (this.countryList.length > 1) throwError("country", this.countryList);
      if (this.organizationList.length > 1) throwError("organization", this.organizationList);
    },
  },
};
</script>
