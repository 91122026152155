<template>
  <v-form ref="form" class="pa-5">

    <h3 class="blue--text text--darken-1 mb-4">Display</h3>

    <v-select ref="ShowUsage" class="form-text-box" outlined dense label="Show Usage" :items="mixinGetShowUsageList()" v-model="ShowUsage" @input="mixinUpdateFormField('display.ShowUsage', `${configPath}.ShowUsage`, ShowUsage)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'ShowUsage')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select v-if="this.getterGetCurrentDeviceModelAlias === 'RG3102'" ref="DisplayTheme" class="form-text-box" outlined dense label="Display Theme" :items="mixinDisplayThemeList()" v-model="DisplayTheme" @input="mixinUpdateFormField('display.DisplayTheme', `${configPath}.DisplayTheme`, DisplayTheme)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'DisplayTheme')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select ref="DisplayTimeout" class="form-text-box" outlined dense label="Device Display Timeout" :items="mixinGetDisplayTimeoutList()" v-model="DisplayTimeout" @input="mixinUpdateFormField('display.DisplayTimeout', `${configPath}.DisplayTimeout`, DisplayTimeout)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'DisplayTimeout')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select ref="LEDIndicatorEnable" class="form-text-box" outlined dense label="Power LED Indicator" :items="mixinGetEnableList()" v-model="LEDIndicatorEnable" @input="mixinUpdateFormField('display.LEDIndicatorEnable', `${configPath}.LEDIndicatorEnable`, LEDIndicatorEnable)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'LEDIndicatorEnable')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <v-select ref="DisplayWiFiNamePassword" class="form-text-box" outlined dense label="Display Wi-Fi SSID & Password" :items="mixinGetDisplayWifiInfoList()" v-model="DisplayWiFiNamePassword" @input="mixinUpdateFormField('display.DisplayWiFiNamePassword', `${configPath}.DisplayWiFiNamePassword`, DisplayWiFiNamePassword)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'DisplayWiFiNamePassword')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <!-- This field should not be visible for RG3102 -->
    <v-select v-if="getterGetCurrentDeviceModelAlias === 'RG3100'" ref="WebAdminPageInfoDisplay" class="form-text-box" outlined dense label="Display Web Admin Page Info" :items="mixinGetEnableList()" v-model="WebAdminPageInfoDisplay" @input="mixinUpdateFormField('display.WebAdminPageInfoDisplay', `${configPath}.WebAdminPageInfoDisplay`, WebAdminPageInfoDisplay)">
      <template v-if="mixinCheckGroupConfigChange('MISC', 'WebAdminPageInfoDisplay')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js'
  import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';
  import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

  export default {
    mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
    components: {
      UnchangedGroupConfigIcon,
    },
    data(){
      return {
        ShowUsage: -1,
        DisplayTimeout: -1,
        LEDIndicatorEnable: -1,
        DisplayWiFiNamePassword: -1,
        WebAdminPageInfoDisplay: -1,
        DisplayTheme: -1,
        
        configPath: "configuration_module.MISC.Value"
      }
    },
    created() {
      this.initMisc();
    },
    watch: {
      // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
      '$store.state.newDeviceConfigurations': {
        handler(){
          this.initMisc();
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentDeviceModelAlias"]),
    },
    methods: {
      initMisc(){
        const defaultMisc = this.getterGetNewDeviceConfigurations.configuration_module.MISC.Value;
        this.ShowUsage = defaultMisc.hasOwnProperty("ShowUsage") ? defaultMisc.ShowUsage : -1;
        this.DisplayTimeout = defaultMisc.hasOwnProperty("DisplayTimeout") ? defaultMisc.DisplayTimeout : -1;
        this.LEDIndicatorEnable = defaultMisc.hasOwnProperty("LEDIndicatorEnable") ? defaultMisc.LEDIndicatorEnable : -1;
        this.DisplayWiFiNamePassword = defaultMisc.hasOwnProperty("DisplayWiFiNamePassword") ? defaultMisc.DisplayWiFiNamePassword : -1;
        this.WebAdminPageInfoDisplay = defaultMisc.hasOwnProperty("WebAdminPageInfoDisplay") ? defaultMisc.WebAdminPageInfoDisplay : -1;

        if(this.getterGetCurrentDeviceModelAlias === "RG3102"){
          this.DisplayTheme = defaultMisc.hasOwnProperty("DisplayTheme") ? defaultMisc.DisplayTheme : -1;
        }

        this.$nextTick(() => {
          this.mixinValidateForm('display', 'display');
        })
      }
    }
  }
</script>