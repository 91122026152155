<template>
  <CardGroupSlot v-if="filteredDiffs.length || relationalDiffs.length" :CardGroupTitle="'Main and Guest Wi-Fi'" :isPreview="isPreview">
    <template #body>
      <transition-group name="fade">
        <div class="history-list my-2" v-for="diff in filteredDiffs" :key="diff.path">
          <SingleCard type="single" :diff="diff" :propertyNameTable="mixinFieldNames" />
        </div>
        <!-- RelationalSingleCard has been implemented because of the terrible spec that no one bothered to check -->
        <div class="history-list my-2" v-for="diff in relationalDiffs" :key="diff.path">
          <RelationalSingleCard type="single" :diff="diff" :propertyNameTable="mixinFieldNames" />
        </div>
      </transition-group>
    </template>
  </CardGroupSlot>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import CardGroupSlot from "../CardComponents/CardGroupSlot.vue";
import SingleCard from "../CardComponents/SingleCard.vue";
import RelationalSingleCard from "../CardComponents/RelationalSingleCard.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [mixin_config_history_utils],
  props: {
    groupDiffs: Array,
    isPreview: Boolean
  },
  components: {
    SingleCard,
    RelationalSingleCard,
    CardGroupSlot,
  },
  data(){
    return {
      filteredDiffs: [],
      relationalDiffs: []
    }
  },
  computed: {
    ...mapGetters(["getterGetDeviceConfigurations", "getterGetNewDeviceConfigurations"])
  },
  // filter out "TotalConnections" since it is never used for RG---- models
  watch: {
    groupDiffs: {
      handler() {
        this.filteredDiffs = this.groupDiffs.filter((item) => (item.path.split(".")[3] !== "TotalConnections" && item.path.split(".")[4] !== "Mode" && item.path.split(".")[4] !== "Bandwidth" && item.path.split(".")[4] !== "Channels" && item.path.split(".")[4] !== "AuthenticationMethod"));

        const tempRelationalDiff = this.groupDiffs.filter((item) => (item.path.split(".")[4] === "Mode" || item.path.split(".")[4] === "Bandwidth" || item.path.split(".")[4] === "Channels" || item.path.split(".")[4] === "AuthenticationMethod"));

        this.relationalDiffs = tempRelationalDiff.map(item => {
          if(item.path.split(".")[3] === "MainWiFi"){
            item.relatedBefore = {
              currentRadioBand: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand,
              otherRadioBand: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand,
              bandwidth: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.Bandwidth,
            }
            item.relatedAfter = {
              currentRadioBand: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand,
              otherRadioBand: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand,
              bandwidth: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.Bandwidth,
            }
          }
          if(item.path.split(".")[3] === "Guest3WiFi"){
            item.relatedBefore = {
              currentRadioBand: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand,
              otherRadioBand: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand,
              bandwidth: this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.Bandwidth,
            }
            item.relatedAfter = {
              currentRadioBand: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand,
              otherRadioBand: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.RadioBand,
              bandwidth: this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.Bandwidth,
            }
          }
          return item;
        });
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
