<template>
  <v-card class="d-flex flex-column" flat>
    <v-card-title>Device Status</v-card-title>
    <v-card-text class="d-flex flex-column my-auto">
      <template v-for="(row, rowi) in splitComponentList">
        <v-row class="d-flex justify-space-around"> 
          <template v-for="(item, x) in row">
            <v-col class="d-flex flex-column align-center mt-4" :cols="colWidths[rowi]">
              <slot :name="item" />
            </v-col>
            <v-divider v-if="x !== row.length - 1" vertical inset/>
          </template>
        </v-row>
        <v-divider v-if="rowi !== numRows - 1"/>
      </template>
    </v-card-text>
  </v-card>
</template>
  
<script>

export default {
  name: "DeviceStatusCardTemplate",
  props: {
    maxPerRow : {
      type: Number,
      default: 4
    },
    componentList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    console: () => console,
    colWidths() {
      return this.countsPerRow.map((count) => {
        return Math.floor(12 / count);
      });
    },
    countsPerRow() {
      const numElements = this.componentList.length;

      const numMaxRows = Math.floor(numElements / this.maxPerRow);
      const remainder = numElements % this.maxPerRow;
      const counts = Array(numMaxRows).fill(this.maxPerRow);

      if (remainder === 0) return counts;

      counts.push(remainder);

      if (counts.length === 1) return counts;

      while(counts[0] - counts[counts.length - 1] > 1) {
        counts[0] -= 1;
        counts[counts.length - 1] += 1;

        counts.sort((a, b) => b - a);

      }
      return counts;
    },
    splitComponentList() {
      return this.countsPerRow.reduce((acc, curr) => {
        const row = this.componentList.slice(acc.index, acc.index + curr);
        acc.rows.push(row);
        acc.index += curr;
        return acc;
      }, {rows: [], index: 0}).rows;
    },
    numRows() {
      return this.countsPerRow.length;
    }
  }, 
}

</script>