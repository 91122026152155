<template>
  <v-form class="pa-5">
    <v-tabs v-model="tabIndex" align-with-title @change="setTabPath">
      <v-tabs-slider></v-tabs-slider>
  
      <v-tab v-for="item in tabItems" :key="item.id">{{ item.text }}</v-tab>
    </v-tabs>
  
    <v-tabs-items v-model="tabIndex">
      <v-tab-item v-for="component in tabItems" :key="component.id" eager>
        <component :is="component.name" :id="component.id" @changeValidState="changeValidState" />
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
import IPv4BlackList from './SubComponents/IPv4BlackList.vue';
import IPv6WhiteList from './SubComponents/IPv6WhiteList.vue';
import { mapGetters, mapMutations } from 'vuex';
  // Make sure to create a script to make the form run validation checks initially
  export default {
    components: {
      IPv4BlackList,
      IPv6WhiteList,
    },
    watch: {
      getterGetCurrentError: {
        handler(newVal){
          if(newVal && newVal.path){
            let errorMenu = newVal.path.split('.')[1];
            console.log(newVal);
            this.$nextTick(() => {
              if(this.tabErrorMap[errorMenu]) this.tabIndex = this.tabErrorMap[errorMenu];
            })
          }
        },
        immediate: true,
        deep: true
      },
      "$store.state.device.currentMenuPath": {
        handler(newVal){
          if(newVal === "security") {
            this.mutationSetCurrentMenuPath(`${newVal}.${this.tabItems[this.tabIndex].id}`);
          }
        }
      }
    },
    data () {
      return {
        tabIndex: 0,
        tabItems: [
          { name: "IPv4BlackList", id: "portBlackList", text: "IPv4 Blacklist" },
          { name: "IPv6WhiteList", id: "portWhiteList", text: "IPv6 Whitelist" },
        ],
        tabErrorMap: {
          portBlackList: 0,
          portWhiteList: 1
        }
      }
    },
    computed: {
      ...mapGetters(["getterGetCurrentError", "getterGetCurrentUserRole", "getterGetCurrentMenuPath"]),
    },
    methods: {
      ...mapMutations(["mutationSetCurrentMenuPath"]),
      changeValidState(validityObj) {
        this.$emit("changeValidState", validityObj);
      },
      setTabPath(){
        let mainMenuPath = this.getterGetCurrentMenuPath.split(".")[0];
        this.mutationSetCurrentMenuPath(`${mainMenuPath}.${this.tabItems[this.tabIndex].id}`);
      }
    }
  }
</script>