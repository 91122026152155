import { mixin_error_list_utils } from "@/mixins/errorListUtils.js";
import { mapGetters, mapMutations } from "vuex";

export const mixin_form_validation_utils = {
  mixins: [ mixin_error_list_utils ],
  data(){
    return {
      mixinRawMACAddress: '',
      // Commonly used
      spacesStartEndRegex: /^\s|\s$/,
      commonNameRegex: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*-_?\s]*$/,
      commonUsernameRegex: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*-_?]*$/,
      commonNameStartEndSpecialCharacter: /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/,
      // This is used for wifi-password, RouterAdmin password
      commonPasswordRegex: /^[a-zA-Z0-9!@#$%^&*-_?]*$/,

      // Login Related
      loginEmailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      loginPasswordRegex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).+$/,

      // Wi-Fi Name related
      wifiNameRegex: /^[a-zA-Z0-9\s\-_.]*$/,
      // Wi-Fi password related
      apnNameRegex: /^[a-zA-Z0-9\s\-.]*$/,
      mfaCodeRegex: /^\d{6}$/,
      IPv4Regex: /^(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|)$/,
      IPOctetRegex: /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/,
      portNumberRegex: /^(?:6553[0-5]|655[0-2]\d|65[0-4]\d\d|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{0,3}|0)$/,
      MACAddressRegex: /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/,
      RawMACAddressRegex: /^[0-9A-Fa-f]{12}$/,
      IMEIRegex: /^\d{15}$/
    }
  },
  computed: {
    ...mapGetters(["getterGetCurrentDeviceModel", "getterGetNewDeviceConfigurations"]),
    mixinFormattedMacAddress: {
      get() {
        // Format the raw MAC address by inserting colons
        return (this.mixinRawMACAddress.toUpperCase().match(/.{1,2}/g) || []).join(':');
      },
      set(value) {
        // Remove colons and store the raw value for internal use
        this.mixinRawMACAddress = value.replace(/[^0-9A-Fa-f]/gi, '').toUpperCase();
      },
    },
  },
  methods: {
    ...mapMutations(["mutationUpdateConfigurations", "mutationDiffConfiguration", "mutationUpdateFormValidation", "mutationSetSnackBarItem", "mutationAddError", "mutationRemoveError" ]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // Functions below prevent key presses for certain types of fields
    // It should be used with @keypress event
    // Prevent key press
    mixinNumberOnlyKeyPress(event) {
      let charCode = event.which ? event.which : event.keyCode;
      // Prevent default if not a number key and not a decimal point (.)
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault();
      }
    },
    mixinNumberOnlyPaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^\d+$/.test(paste)) {
        event.preventDefault();
      }
    },
    mixinFormatMACAddress(event) {
      // Allow only hex characters and limit length to 12 (6 pairs of hex digits)
      if (!/[0-9A-F]/i.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
    mixinPasteMACAddress(event) {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('text');
      const hexPattern = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/i;
      const plainPattern = /^[0-9A-Fa-f]{12}$/i;

      if (hexPattern.test(pasteData)) {
        this.mixinRawMACAddress = pasteData.replace(/:/g, '');
      } else if (plainPattern.test(pasteData)) {
        this.mixinRawMACAddress = pasteData;
      } else {
        this.triggerSnackBar("error", "Please paste a valid MAC address format.")
      }
    },

    // This function will also be used for the initial form validation as forms get loaded in with variables
    mixinValidateForm(formId, menuId){
      const isFormValid = this.$refs.form && this.$refs.form.validate();
      this.mutationUpdateFormValidation({ formId: formId, valid: isFormValid });
      this.$emit("changeValidState", { id: menuId, valid: isFormValid });
    },
    // All forms' fields will be validated and its values updated to vuex state by this function
    // This does 3 things
    // 1. Check for field's validation before updating user's newly input value
    // 2. Run json compare function to update Config History Panel
    // 3. emit changed validity state of the form to main config component. This should show/hide error mark on the side menu
    // formId: This is used to check whether the form is valid or not
    // menuId: This is used send for validation signal to the side menu. If the form with match menuId is invalid, error icon will appear on the side menu
    // fieldRefs: this is the reference string of the field. Based on the validity of single field, the data vuex will be changed or remain the same
    // path: json path of the field that is being changed. The temporary vuex configuration state will be changed based on this
    // value: new value of the field. "mutationUpdateConfigurations" method will find the structure property and update it to this new value
    // deleteFlag: in case the field for the given path needs to be deleted, set this flag to true
    
    mixinUpdateFormField(menuPath, configPath, value, deleteFlag = false, toggleButton = false){
      const menuPathArray = menuPath.split('.');
      const formId = menuPathArray.length > 2 ? menuPathArray[1] : menuPathArray[0];
      const menuId = menuPathArray[0];
      const fieldRef = menuPathArray[menuPathArray.length - 1];
      const isFieldValid = !toggleButton ? this.$refs[fieldRef].validate() : true;
      if(isFieldValid){
        // add a function to remove the error from vuex array
        this.mutationRemoveError(menuPath);
        this.mutationUpdateConfigurations({ path: configPath, value, deleteFlag });
        this.mutationDiffConfiguration();
      } else {
        // add a function to add error to the vuex array
        this.mutationAddError({ 
          path: menuPath,
          pathString: this.mixinGetConfigPath(this.getterGetCurrentDeviceModel, menuPath),
          errorMessage: this.mixinGetErrorMessage(fieldRef)
        });
      }
      // emit true or false to make sure that side menu icon changes according to validity of the form
      this.mixinValidateForm(formId, menuId);
    },
    // retrieve error message string for error panel
    mixinGetErrorMessage(reference) {
      const errorMessageElement = this.$refs[`${reference}`].$el.querySelector('.v-messages__message');
      return errorMessageElement ? errorMessageElement.textContent : false;
    },
    mixinCheckGroupConfigChange(mainPath, subPath) {
      const UpdatedValue = this.getterGetNewDeviceConfigurations.configuration_module[mainPath].UpdatedValue;
      if(UpdatedValue && UpdatedValue.length > 0) {
        return UpdatedValue.some(change => change.key === subPath);
      }
      return false;
    },
    // This is a all purpose form validation method.
    // All form components with v-form tag will have ref="form" prop
    // Login related
    mixinLoginEmailRule(){
      return [
        value => !!value || 'Enter your email address',
        value => this.loginEmailRegex.test(value) || 'Enter a valid email address'
      ]
    },
    mixinLoginPasswordRule() {
      return [
        value => !!value || "Enter your password",
        value => value.length >= 8 || "The password must be at least 8 characters long", 
        value => this.loginPasswordRegex.test(value) || 'The password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number'
      ]
    },
    mixinMfaCodeRule(){
      return [
        value => !!value || 'Type in 6 digit mfa code',
        value => this.mfaCodeRegex.test(value) || 'Type in 6 digit mfa code'
      ]
    },
    mixinPersonNameRule(name){
      return [
        value => !!value || `Enter your ${name} name`,
        value => value.length >= 2 || `Your ${name} name must be at least 2 characters long`,
        value => /^[^\s]+$/.test(value) || `Your ${name} name cannot contain any spaces`
      ]
    },
    
    // Configuration form related
    mixinIsDefinedAndNotNull(property){
      return property !== undefined && property !== null
    },
    // Common name validation. This is for names used just for the sake of list item naming.
    mixinCommonNameRule(nameString = 'A name is required'){
      return [
        value => !!value || nameString,
        value => this.commonNameStartEndSpecialCharacter.test(value) || 'Cannot start or end with a special character or spaces.',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        value => (value && value.length <= 63) || 'Maximum length is 63 characters.',
        value => this.commonNameRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), space, and some special characters(!@#$%^&*-_?) are allowed',
      ]
    },
    mixinCommonUsernameRule(){
      return [
        value => !!value || 'Username is required',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        value => (value && value.length <= 63) || 'Maximum length is 63 characters.',
        value => this.commonUsernameRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9) and some special characters(!@#$%^&*-_?) are allowed'
      ]
    },
    mixinCommonPasswordRule() {
      return [
        value => !!value || 'Password is required',
        value => this.commonNameStartEndSpecialCharacter.test(value) || 'Cannot start or end with a special character or spaces.',
        value => (value.length >= 2 && value.length <= 63) || 'Must be 2 to 63 characters long.',
        value => this.commonPasswordRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), and some special characters(!@#$%^&*-_?) are allowed',
      ]
    },
    mixinWifiNameRule(){
      return [
        value => !!value || 'This field is required.',
        value => this.commonNameStartEndSpecialCharacter.test(value) || 'Cannot start or end with a special character or spaces.',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        value => (value && value.length <= 32) || 'Maximum length is 32 characters.',
        value => this.wifiNameRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), period(.), dash(-), and underscore(_) characters are allowed',
      ];
    },
    mixinWifiPasswordRule(){
      return [
        value => !!value || 'This field is required.',
        value => !this.spacesStartEndRegex.test(value) || 'Cannot start or end with spaces.',
        value => (value && value.length >= 8) || 'Minimum length is 8 characters.',
        value => (value && value.length <= 63) || 'Maximum length is 63 characters.',
        value => this.commonPasswordRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), and some special characters(!@#$%^&*-_?) are allowed',
      ]
    },
    mixinApnNameRule() {
      return [
        value => !!value || 'This field is required.',
        value => this.commonNameStartEndSpecialCharacter.test(value) || 'Cannot start or end with a special character or spaces.',
        value => (value && value.length >= 2) || 'Minimum length is 2 characters.',
        value => (value && value.length <= 63) || 'Maximum length is 63 characters.',
        value => this.apnNameRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), period(.), and dash(-) characters are allowed',
      ]
    },
    mixinInactiveTimeRule(){
      return [
        value => (this.mixinIsDefinedAndNotNull(value) && value !== "") || 'Inactive time is a required field. Enter a value between 0 and 86,400',
        value => (value >= 0 && value <= 86400) || 'Inactive time value must be between 0 and 86,400'
      ]
    },
    // This is here temporarily
    mixinRouterAdminPasswordRule() {
      return [
        value => (value.length >= 8 && value.length <= 16) || 'Must be 8 to 16 characters long.',
        // value => this.commonPasswordRegex.test(value) || 'Only alphanumeric characters(a-z, A-Z, 0-9), and some special characters(!@#$%^&*-_?) are allowed',
        // value => /[A-Za-z]/.test(value) || 'Must contain at least one alphabet character.',
        // value => !/\d{4,}/.test(value) || 'Cannot have more than 3 consecutive digits.'
      ]
    },
    mixinUsageLimitRule(){
      return [
        value => value > 0 || "Must be a number greater than 0",
      ]
    },
    mixinOptionalIPv4Rule() {
      return [
        value => this.IPv4Regex.test(value) || "Enter IPv4 address in form of Y.Y.Y.Y where Y = 0~255."
      ]
    },
    mixinMandatoryIPv4Rule(){
      return [
        value => (this.mixinIsDefinedAndNotNull(value) && value !== "") || "Enter IPv4 address in form of Y.Y.Y.Y where Y = 0~255.",
        value => this.IPv4Regex.test(value) || "Enter IPv4 address in form of Y.Y.Y.Y where Y = 0~255."
      ]
    },
    mixinPortNumberRule(){
      return [
        value => this.portNumberRegex.test(value) || "Enter a port number ranging in 1~65535."
      ]
    },
    mixinMACAddressRule(){
      return [
        value => this.MACAddressRegex.test(value) || "Enter a MAC Address in form of YY:YY:YY:YY:YY:YY where Y  = 0~9 or A~F."
      ]
    },
    mixinRawMACAddressRule(){
      return [
        value => this.RawMACAddressRegex.test(value) || "Enter a 12 character long MAC Address where the characters are 0~9 or A~F."
      ]
    },
    mixinIMEIRule(){
      return [
        value => this.IMEIRegex.test(value) || "Enter a 15 digit IMEI number."
      ]
    },

    // This is a function is a manual way of testing these rules in script
    mixinScriptValidation(value, rules) {
      for (let rule of rules) {
        const validationResult = rule(value);
        if (validationResult !== true) {
          return { valid: false, message: validationResult }; // Return the first validation error encountered
        }
      }
      return { valid: true }; // If all validations pass, return true
    }
  }
}