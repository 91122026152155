import { API, graphqlOperation } from "aws-amplify";
import { get_connected_status_for_router, list_dhcp_client_for_particular_router, get_configuration_of_router, get_router_list, get_router_details_for_the_particular_user, get_router_check_exists_or_not, edit_router_details, resetRouter, delete_router_details, reboot_router, update_configuration_of_router, update_router_groups_configuration, request_query_to_router, individal_bulk_upload_router, get_cellular_data, upload_router_mac_address_csv } from "@/graphql";

// ----------------------queries-------------------------
export const getDeviceList = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_router_list, {
        input: queryObj,
      })
    );
    const response = JSON.parse(result.data.get_router_list);
    return response;
  } catch (error) {
    console.error("Error fetching device list", error);
    throw error;
  }
};

export const getSingleUserDeviceList = async (queryObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_router_details_for_the_particular_user, {
        input: queryObj
      })
    );
    const response = JSON.parse(result.data.get_router_details_for_the_particular_user);
    return response
  } catch (error) {
    console.error("Error fetching devices", error);
    throw error;
  }
}

// This query retrieves single device's WAN related information such as IPv4/IPv6 details
export const getDeviceWANStatus = async (routerId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_connected_status_for_router, {
        input: {
          router_id: routerId,
        },
      })
    );
    const response = JSON.parse(result.data.get_connected_status_for_router);
    return response;
  } catch (error) {
    // delete throw error needs to be changed later
    // console.error("Error fetching device WAN status", error);
  }
};

export const getCellularData = async (routerId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_cellular_data, {
        input: {
          router_id: routerId,
        },
      })
    );
    const response = JSON.parse(result.data.get_router_cellular);
    return response;
  } catch (error) {
    console.error("Error fetching device's cellular info", error);
    throw error;
  }
};

// This query retrieves information for DHCP clients that are connected to a single device
export const getDeviceDHCPClients = async (routerID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(list_dhcp_client_for_particular_router, {
        input: {
          router_id: routerID,
        },
      })
    );
    const response = JSON.parse(result.data.list_dhcp_client_for_particular_router).items;
    return response;
  } catch (error) {
    console.error("Error fetching device's DHCP clients", error);
    throw error;
  }
};

// This query retrieves single device's configurations for configuration editing or any other basic information
export const getDeviceConfigurations = async (routerID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_configuration_of_router, {
        input: {
          router_id: routerID,
        },
      })
    );
    const response = JSON.parse(result.data.get_configuration_of_router);
    return response;
  } catch (error) {
    console.error("Error fetching device's configurations", error);
    throw error;
  }
};

export const requestQueryToRouter = async (routerID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(request_query_to_router, {
        input: {
          router_id: routerID,
        },
      })
    );
    const response = result;
    return response;
  } catch (error) {
    console.error("Error fetching device's configurations", error);
    throw error;
  }
};

export const checkDeviceExists = async (routerID) => {
  try {
    const result = await API.graphql(
      graphqlOperation(get_router_check_exists_or_not, {
        input: {
          router_id: routerID,
        },
      })
    )
    const response = JSON.parse(result.data.get_router_check_exists_or_not).router.router_id;
    return response;
  } catch (error) {
    console.error("Error fetching device's configurations", error);
    throw error;
  }
};


// ----------------------mutations--------------------------
// Edit device name or description
export const setDeviceDetails = async (changedDeviceDetails) => {
  try {
    const result = await API.graphql(
      graphqlOperation(edit_router_details, {
        input: changedDeviceDetails
      })
    );
    const response = result;
    return response;
  } catch (error) {
    console.error("Error, couldn't change device's name or description", error);
    throw error;
  }
};

export const resetRouterConfigs = async (resetObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(resetRouter, {
        input: resetObj
      })
    );
    const response = result.data.resetRouter;
    return response
  } catch (error) {
    console.error("Error, couldn't reset devices' configs", error);
    throw error;
  }
};


export const deleteDevices = async (deleteObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(delete_router_details, {
        input: deleteObj
      })
    );
    // const response = result.data.resetRouter;
    // return response
  } catch (error) {
    console.error("Error, couldn't reset devices' configs", error);
    throw error;
  }
};

export const sendCommandToDevice = async (commandObj) => {
  try {
    const result = await API.graphql(
      graphqlOperation(reboot_router, {
        input: commandObj
      })
    );
    // const response = result.data.resetRouter;
    // return response
  } catch (error) {
    console.error("Error, couldn't send commands to the device", error);
    throw error;
  }
};

export const setDeviceConfigurations = async (payload) => {
  try {
    const result = await API.graphql(
      graphqlOperation(update_configuration_of_router, {
        input: payload
      })
    );
  } catch(error) {
    console.error("Error, couldn't set device configuration", error);
    throw error;
  }
}

export const setGroupConfigurations = async (payload) => {
  try {
    const result = await API.graphql(
      graphqlOperation(update_router_groups_configuration, {
        input: payload
      })
    );
  } catch(error) {
    console.error("Error, couldn't set device configuration", error);
    throw error;
  }
}

export const addNewDevice = async (data) => {
  try {
    const result = await API.graphql(
      graphqlOperation(individal_bulk_upload_router, {
        input: data,
      })
    );
  } catch(error) {
    console.error("Error, couldn't set device configuration", error);
    throw error;
  }
}

export const registerDeviceDefaultMACAddress = async (subKey) => {
  try {
    const result = await API.graphql(
      graphqlOperation(upload_router_mac_address_csv, { input: { s3_key: subKey } })
    );
    return result;
  } catch(error) {
    console.error("Error, couldn't set device configuration", error);
    throw error;
  }
}