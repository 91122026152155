<template>
  <v-dialog overflow-hidden v-model="MACFilteringRulesDialog" persistent max-width="600px" scrollable>
    <DialogCard :modalIcon="'table-filter'" :modalTitle="'MAC Filtering Rules'">
      <template #header>
        <v-btn icon x-small @click="close_mac_filtering_rules_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <div>
            <v-switch ref="ActiveRule" class="form-text-box d-inline-block mb-6" dense v-model="ActiveRule" hide-details>
              <template v-slot:label>
                <transition-group name="fade" tag="div" class="radio-button-enable-disable-container">
                  <div v-if="ActiveRule" key="Enabled">Enabled</div>
                  <div v-else key="Disabled">Disabled</div>
                </transition-group>
              </template>
            </v-switch>
          </div>

          <v-text-field ref="RuleName" class="form-text-box" outlined dense label="Rule Name" placeholder="Rule Name" v-model="RuleName" :rules="mixinCommonNameRule()" autocomplete="new-password" />

          <v-text-field ref="HostName" class="form-text-box" outlined dense label="Host Name" placeholder="Host Name" v-model="HostName" :rules="mixinCommonNameRule()" autocomplete="new-password" />

          <div class="d-flex justify-end mb-6">
            <v-btn class="mr-2 white--text" x-small color="blue" @click="window='text'" :disabled="window==='text'">Enter MAC Address Manually</v-btn>
            <v-btn class="white--text" x-small color="red" @click="window='list'" :disabled="window==='list'">Select MAC Address From List</v-btn>
          </div>

          <v-window v-model="window">
            <!-- textbox -->
            <v-window-item :value="'text'">
              <div class="pa-2">
                <v-fade-transition>
                  <v-text-field v-if="window==='text'" ref="MACAddress" class="form-text-box" outlined dense label="MAC Address" placeholder="MAC Address" v-model="mixinFormattedMacAddress" :rules="mixinMACAddressRule()" autocomplete="new-password" maxlength="17" @paste="mixinPasteMACAddress($event)" @keypress="mixinFormatMACAddress($event)" />
                </v-fade-transition>
              </div>
            </v-window-item>
            <!-- table selection -->
            <v-window-item :value="'list'">
              <v-data-table class="connected-table dtwidth" :mobile-breakpoint="0" :headers="routerDeviceData" :items="getDeviceDHCPClients" v-model="SelectedDevice" show-select single-select :sort-by="['updated_on']" :sort-desc="[true]" dense item-key="mac_address">
                <template v-slot:[`item.host_name`]="{ item }">
                  <span class="caption" :class="item.connected ? 'primary-blue--text' : 'grey--text'">{{ item.host_name }}</span>
                </template>
                <template v-slot:[`item.updated_on`]="{ item }">
                  <span class="caption">{{ mixinConvertUTCToDate(item.updated_on) }}</span>
                </template>
                <template v-slot:[`item.ip_address`]="{ item }">
                  <span class="caption">{{ item.ip_address }}</span>
                </template>
    
                <template v-slot:[`item.interface_type`]="{ item }">
                  <v-chip x-small :color="item.interface_type == 'Wireless' ? 'orange' : 'primary'" class="white--text my-2">
                    {{ item.interface_type == "Wireless" ? "Wi-Fi" : "Ethernet" }}
                  </v-chip>
                </template>
                <template v-slot:[`item.mac_address`]="{ item }">
                  <span class="caption" v-text="item.mac_address ? item.mac_address : '-'"></span>
                </template>
              </v-data-table>
            </v-window-item>
          </v-window>
          
        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_mac_filtering_rules_dialog()">Cancel</v-btn>
        <v-btn small @click="save_mac_filtering_rules()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils.js";
import { mapMutations, mapGetters } from "vuex";


export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils, mixin_time_utils],
  components: {
    DialogCard,
  },
  props: {
    MACFilteringRulesDialog: Boolean,
    MACFilteringRulesDialogMode: Object,
    MACFilteringRules: Array,
  },

  data() {
    return {
      ActiveRule: false,
      RuleName: "",
      HostName: "",
      SelectedDevice: [],

      window: "text",

      routerDeviceData: [
        { text: "Host Name", value: "host_name", class: "black--text" },
        { text: "Last Connected", value: "updated_on", class: "black--text" },
        { text: "IP Address", value: "ip_address", class: "black--text" },
        { text: "MAC Address", value: "mac_address", class: "black--text" },
        { text: "Interface", value: "interface_type", class: "black--text" },
      ],
    };
  },
  watch: {
    MACFilteringRulesDialog: {
      handler(newVal) {
        if (newVal) {
          this.initMACFilteringRulesDialog();
        } else {
          this.window = "text"
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters(["getterGetDeviceDHCPClients"]),
    getDeviceDHCPClients() {
      return this.getterGetDeviceDHCPClients;
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initMACFilteringRulesDialog() {
      const mode = this.MACFilteringRulesDialogMode.mode;
      const item = this.MACFilteringRulesDialogMode.item;
      if (mode === "add") {
        this.ActiveRule = true;
        this.RuleName = "";
        this.HostName = "";
        this.mixinRawMACAddress = "";
        this.SelectedDevice = [];
      } else if (mode === "edit") {
        this.ActiveRule = this.mixinIsDefinedAndNotNull(item.ActiveRule) ? (item.ActiveRule ? true : false) : false;
        this.RuleName = this.mixinIsDefinedAndNotNull(item.RuleName) ? item.RuleName : "";
        this.HostName = this.mixinIsDefinedAndNotNull(item.HostName) ? item.HostName : "";
        this.mixinRawMACAddress = this.mixinIsDefinedAndNotNull(item.MACAddress) ? item.MACAddress : "";
      }
    },
    save_mac_filtering_rules() {
      if (!(this.$refs.form && this.$refs.form.validate())) return this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
      if(this.window === "list" && !this.SelectedDevice.length) return this.triggerSnackBar("error", "Select a device for MAC Address.");
      // if(this.MACFilteringRules.some(rule => rule.HostName === this.HostName)) return this.triggerSnackBar("error", "Cannot have duplicate host name.");
      if(this.window === "list" && this.MACFilteringRules.some(rule => rule.MACAddress === (this.SelectedDevice[0] ? this.SelectedDevice[0].mac_address : ""))) return this.triggerSnackBar("error", "Cannot have duplicate MAC Adddresses.");
      if(this.window === "text" && this.MACFilteringRules.some(rule => rule.MACAddress === this.mixinRawMACAddress)) return this.triggerSnackBar("error", "Cannot have duplicate MAC Adddresses.");

      const mode = this.MACFilteringRulesDialogMode.mode;
      const item = {
        Index: mode === "add" ? this.MACFilteringRules.length : this.MACFilteringRulesDialogMode.item.Index,
        ActiveRule: this.ActiveRule ? 1 : 0,
        RuleName: this.RuleName,
        HostName: this.HostName,
        MACAddress: this.window === "text" ? this.mixinRawMACAddress : this.SelectedDevice[0] ? this.SelectedDevice[0].mac_address : ""
      };

      const dialogObj = {
        status: 1,
        mode: mode,
        item: item,
      };
      if (mode === "edit") dialogObj.index = this.MACFilteringRulesDialogMode.index;

      // Set item to send back to ApnInfo.vue component for edit or add
      this.$emit("close", dialogObj);
    },
    //method invokes on close actions
    close_mac_filtering_rules_dialog() {
      this.$emit("close", { status: 0 });
    },
  },
};
</script>

<style scoped>
.radio-button-enable-disable-container {
  position: relative;
}

.radio-button-enable-disable-container > * {
  position: absolute;
  top: -0.67rem;
  left: 0.5rem;
}
</style>
