export default {
    model: "Rg35Model",
    modelName: "RG350",
    displayOptions: {
        showIMEI: true,
        showSignal: true,
        showBatterLevel: true,
        showDns: true,
        showLocation: true,
        isMobileDevice: true,
    }, 
    configurations: {
        canConfigMainWifi(routerDetail) { 
            return true;
        },
        canConfigConnections(routerDetail) { 
            return true;
        },
        canConfigDisplay(routerDetail) { 
            return true;
        },
        canConfigControl(routerDetail) { 
            return true;
        },
        canConfigDataUsage(routerDetail) { 
            return true;
        },
        canConfigLANSettings(routerDetail) { 
            return true;
        },
        canConfigDHCPRange(routerDetail) { 
            return true;
        },
        canConfigSecurity(routerDetail) { 
            return true;
        },
        canConfigBasicSecurity(routerDetail) { 
            return true;
        },
        canConfigMACFiltering(routerDetail) { 
            return true;
        },
        canConfigTODAccess(routerDetail) { 
            return true;
        },
        canConfigDebugCelluar(routerDetail) { 
            return true;
        },
        
    } 
}